<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Días no laborales</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                <div class="input-group">
                  <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                  </div>
                </div>                
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateDianol', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Día no laboral</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Fecha</td>
                        <td>Descripción</td>
                        <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items">
                        <td class="text-center">
                          <button v-on:click="editItem(item._id)" class="btn btn-primary" v-if="item.cmp_id != '000000000000000000000000'">Editar</button>                        
                        </td>
                        <td>{{ item.dnl_fecha | formatDateUtc }}</td>
                        <td>{{ item.dnl_descripcion }}</td>
                        <td class="text-center"><input type="checkbox" v-bind:value="item._id" v-model="delItems" v-if="item.cmp_id != '000000000000000000000000'"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
            </div>
            <br>
            <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
              <i data-feather="delete"></i>Eliminar
            </button>
            <!--{{delItems}}-->
          </div>
        </div>
      </div>
    </div>
    <ModalForm :visible="show" @close="show=false" :message="'Por favor espere mientras se consultan los datos..'" />
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import ModalForm from "../modal/ModalForm.vue";
import { formatDateUtc } from '../../plugins/filters';

export default {
  components: {
    //VIcon,
    ModalForm,
  },
  data() {
    return {
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords: "",
      search: {},
      filter: false, // Items are filtered
      show: false,
    };
  },
  filters: {
    formatDateUtc
  },
  computed: {
    checkedItems() {
      if (this.delItems.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function() {
    feather.replace();
  },
  created: function() {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.search = { lastYear: new Date().getFullYear() };
      this.searchItems(this.search);
    }
  },
  methods: {
    deleteItems() {
      if (this.delItems.length > 0) {
        const response = confirm("Está seguro que desea eliminar los registros seleccionados?");
        if (response) {
          this.show = true;
          let uri = "/dianols/deleteitems";
          this.axios
            .post(uri, { delItems: this.delItems })
            .then((res) => {
              this.show = false;
              if (this.search) {
                this.searchItems(this.search);
              }
            })
            .catch((err) => {
              this.show = false;
              //alert(err.response.data.msg);
              if (err.response.data.i > 0) {
                if (this.search) {
                  this.searchItems(this.search);
                }
              }
            });
        }
      }
      return;
    },
    checkAllItems() {
      this.delItems = [];
      if (!this.allChecked) {
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].cmp_id){
            this.delItems.push(this.items[i]._id);
          }
        }
      }
    },
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
        this.message = 'Buscando días no laborales';
        this.show = true;
        p.cmp_id = this.$store.state.company;
        let uri = '/dianols/search';
        this.axios.post(uri, p)
        .then(response => {
          this.items = response.data;
          this.search = p;
          this.delItems = [];
          this.filter = true;
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los días no laborales! ' + err;
        });
      }
    },
    closeSearch() {
      this.filter = false;
      this.search = {};
      this.keywords = "";
      this.items = [];
    },
    newSearch () {
      this.search = {};
    },
    createItem() {
      this.$router.push({ name: "CreateDianol", params: { add: true, search: this.search } });
    },
    editItem(id) {
      this.$router.push({ name: "CreateDianol", params: { id: id, add: false, search: this.search } });
    }
  },
};
</script>